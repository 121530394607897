import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

// Images
import BlueLabel from "../images/logo-blue-small.svg"
import SilverLabel from "../images/logo-silver-small.svg"
import GoldLabel from "../images/logo-gold-small.svg"

const Preisuebersicht = () => {
  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/price-list")
    }
  }, [])

  return (
    <React.Fragment>
      <PageTitle title={"Preisübersicht"} />
      <Layout>
        <div className="pricelist-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Preisübersicht</a>
              </li>
            </ul>
          </div>

          <div className="pricelist">
            <div className="contact__box" style={{ height: 345 }}>
              <h1>Preisübersicht</h1>
              <div className="contact-horizontal-line"></div>
              <div className="contact__box--block">
                <p>
                  Es gibt drei Qualitätsstufen des Labels, um unterschiedliche
                  Zielgruppen abdecken zu können. Die Qualitätsstufen
                  unterscheiden sich hinsichtlich ihres Sicherheitsanspruchs
                  (Security Claim) und des Sicherstellungsgrades der Überprüfung
                  (Assurance Level).
                </p>
              </div>
            </div>

            <div className="pricelist__table">
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell"></div>
                <div className="pricelist__table--cell">
                  <img
                    className="mw-100"
                    src={BlueLabel}
                    alt="Cyber Trust Austria Standard Label"
                    title="Cyber Trust Austria Standard Label"
                  />
                </div>
                <div className="pricelist__table--cell">
                  <img
                    className="mw-100"
                    src={SilverLabel}
                    alt="Cyber Trust Austria Silver Label"
                    title="Cyber Trust Austria Silver Label"
                  />
                </div>
                <div className="pricelist__table--cell">
                  <img
                    className="mw-100"
                    src={GoldLabel}
                    alt="Cyber Trust Austria Gold Label"
                    title="Cyber Trust Austria Gold Label"
                  />
                </div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell"></div>
                <div className="pricelist__table--cell">Label</div>
                <div className="pricelist__table--cell">Label Silber</div>
                <div className="pricelist__table--cell">Label Gold</div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell"></div>
                <div
                  className="pricelist__table--cell"
                  style={{ padding: "15px 20px 15px 20px" }}
                >
                  Das Standard Label wendet sich vor allem an kleinere
                  Unternehmen und Organisationen. Die Anforderungen sind
                  Basissicherheitskriterien, welche jedes Unternehmen erfüllen
                  sollte; der Aufwand dafür ist überschaubar. Die Bewertung
                  erfolgt mittels einer validierten Selbstdeklaration. Der
                  Prozess ist daher einfach und rasch durchführbar.
                </div>
                <div
                  className="pricelist__table--cell"
                  style={{ padding: "15px 20px 15px 20px" }}
                >
                  Das Silber Label wendet sich an Unternehmen und
                  Organisationen, die ein höheres Sicherheitsniveau erfüllen
                  müssen oder wollen. Es umfasst die 14
                  Basissicherheitskriterien sowie 11 zusätzliche Kriterien. Die
                  Erfüllung der Anforderungen erfordert entsprechende
                  Vorbereitung und einen gewissen Aufwand. Die Bewertung erfolgt
                  mittels einer validierten Selbstdeklaration. Der Prozess ist
                  daher einfach und rasch durchführbar.
                </div>
                <div
                  className="pricelist__table--cell"
                  style={{ padding: "15px 20px 15px 20px" }}
                >
                  Das Gold Label wendet sich an Unternehmen und Organisationen,
                  die ein höheres Sicherheitsniveau erfüllen müssen oder wollen.
                  Es umfasst die 14 Basissicherheitskriterien sowie 11
                  zusätzliche Kriterien. Die Erfüllung der Anforderungen
                  erfordert entsprechende Vorbereitung und einen gewissen
                  Aufwand. Die Bewertung erfolgt mittels einer validierten
                  Selbstdeklaration, welche für höchste Sicherheitsansprüche
                  zusätzlich durch einen externen Audit bestätigt wird. Dieser
                  Prozess nimmt daher etwas mehr Zeit in Anspruch.
                </div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell">
                  Label Gebühr (1. Jahr)
                </div>
                <div className="pricelist__table--cell">890 €</div>
                <div className="pricelist__table--cell">1390 €</div>
                <div className="pricelist__table--cell">1490 €</div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell">
                  Label Gebühr (Folgejahre)
                </div>
                <div className="pricelist__table--cell">790 €</div>
                <div className="pricelist__table--cell">1190 €</div>
                <div className="pricelist__table--cell">1290 €</div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell">Auditgebühr</div>
                <div className="pricelist__table--cell">—</div>
                <div className="pricelist__table--cell">—</div>
                <div className="pricelist__table--cell">
                  Gemäß Audit-Partner
                </div>
              </div>
              <div className="pricelist__table--row">
                <div className="pricelist__table--cell">Gültigkeit</div>
                <div className="pricelist__table--cell">1 Jahr</div>
                <div className="pricelist__table--cell">1 Jahr</div>
                <div className="pricelist__table--cell">1 Jahr</div>
              </div>
            </div>

            <div className="pricelist__table--ext">
              <div
                className="pricelist__table--row"
                style={{ marginTop: 20, marginBottom: 80 }}
              >
                <div className="pricelist__table--cell"></div>
                <div
                  className="pricelist__table--cell"
                  style={{
                    borderTop: "2px solid #1A55A2",
                    padding: 0,
                    margin: 0,
                    height: 50,
                    border: "none",
                  }}
                >
                  <a
                    style={{
                      // border: "2px solid",
                      // padding: "5px",
                      width: "100%",
                      height: 50,
                      textDecoration: "none",
                      padding: 0,
                      margin: 0,
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="/standard-label-beantragen"
                  >
                    Ihr Weg zum Standard Label
                  </a>
                </div>
                <div
                  className="pricelist__table--cell"
                  style={{
                    borderTop: "2px solid #1A55A2",
                    padding: 0,
                    margin: 0,
                    height: 50,
                    border: "none",
                  }}
                >
                  {" "}
                  <a
                    style={{
                      // border: "2px solid",
                      // padding: "5px",
                      width: "100%",
                      height: 50,
                      textDecoration: "none",
                      padding: 0,
                      margin: 0,
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="/silber-label-beantragen"
                  >
                    Ihr Weg zum Silber Label
                  </a>
                </div>
                <div
                  className="pricelist__table--cell"
                  style={{
                    borderTop: "2px solid #1A55A2",
                    padding: 0,
                    margin: 0,
                    height: 50,
                    border: "none",
                  }}
                >
                  {" "}
                  <a
                    style={{
                      // border: "2px solid",
                      // padding: "5px",
                      width: "100%",
                      height: 50,
                      textDecoration: "none",
                      padding: 0,
                      margin: 0,
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="/gold-label-beantragen"
                  >
                    Ihr Weg zum Gold Label
                  </a>
                </div>
              </div>
            </div>

            <div className="pricelist__table--mobile">
              <div className="pricelist__table--mobile--container">
                <div className="pricelist__table--mobile--image--wrapper">
                  <img
                    className="mw-100"
                    src={BlueLabel}
                    alt="Cyber Trust Austria Standard Label"
                    title="Cyber Trust Austria Standard Label"
                  />
                </div>

                <div className="pricelist__table--mobile--title-wrapper">
                  Label
                </div>

                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label Gebühr (1. Jahr)
                  </div>
                  <div className="pricelist__table--mobile--cell">890 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label Gebühr (Folgejahre)
                  </div>
                  <div className="pricelist__table--mobile--cell">790 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Auditgebühr
                  </div>
                  <div className="pricelist__table--mobile--cell">—</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Gültigkeit
                  </div>
                  <div className="pricelist__table--mobile--cell">1 Jahr</div>
                </div>
              </div>
              <div style={{ marginBottom: 50 }}>
                <a
                  style={{
                    // border: "2px solid",
                    // padding: "5px",
                    width: "100%",
                    height: 50,
                    textDecoration: "none",
                    padding: 0,
                    margin: 0,
                  }}
                  className="white-button-large page__btn page__btn--blue-border"
                  href="/standard-label-beantragen"
                  target="_blank"
                >
                  Ihr Weg zum Standard Label
                </a>
              </div>

              <div className="pricelist__table--mobile--container">
                <div className="pricelist__table--mobile--image--wrapper">
                  <img
                    className="mw-100"
                    src={SilverLabel}
                    alt="Cyber Trust Austria Silver Label"
                    title="Cyber Trust Austria Silver Label"
                  />
                </div>

                <div className="pricelist__table--mobile--title-wrapper">
                  Label Silver
                </div>

                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label Gebühr (1. Jahr)
                  </div>
                  <div className="pricelist__table--mobile--cell">1390 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label Gebühr (Folgejahre)
                  </div>
                  <div className="pricelist__table--mobile--cell">1190 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Auditgebühr
                  </div>
                  <div className="pricelist__table--mobile--cell">—</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Gültigkeit
                  </div>
                  <div className="pricelist__table--mobile--cell">1 Jahr</div>
                </div>
              </div>

              <div style={{ marginBottom: 50 }}>
                <a
                  style={{
                    // border: "2px solid",
                    // padding: "5px",
                    width: "100%",
                    height: 50,
                    textDecoration: "none",
                    padding: 0,
                    margin: 0,
                  }}
                  className="white-button-large page__btn page__btn--blue-border"
                  href="/silber-label-beantragen"
                  target="_blank"
                >
                  Ihr Weg zum Silber Label
                </a>
              </div>

              <div className="pricelist__table--mobile--container">
                <div className="pricelist__table--mobile--image--wrapper">
                  <img
                    className="mw-100"
                    src={GoldLabel}
                    alt="Cyber Trust Austria Gold Label"
                    title="Cyber Trust Austria Gold Label"
                  />
                </div>

                <div className="pricelist__table--mobile--title-wrapper">
                  Label Gold
                </div>

                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label Gebühr (1. Jahr)
                  </div>
                  <div className="pricelist__table--mobile--cell">1490 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Label Gebühr (Folgejahre)
                  </div>
                  <div className="pricelist__table--mobile--cell">1290 €</div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Auditgebühr
                  </div>
                  <div className="pricelist__table--mobile--cell">
                    Gemäß Audit-Partner
                  </div>
                </div>
                <div className="pricelist__table--mobile--row">
                  <div className="pricelist__table--mobile--cell">
                    Gültigkeit
                  </div>
                  <div className="pricelist__table--mobile--cell">1 Jahr</div>
                </div>
              </div>

              <div style={{ marginBottom: 50 }}>
                <a
                  style={{
                    // border: "2px solid",
                    // padding: "5px",
                    width: "100%",
                    height: 50,
                    textDecoration: "none",
                    padding: 0,
                    margin: 0,
                  }}
                  className="white-button-large page__btn page__btn--blue-border"
                  href="/gold-label-beantragen"
                  target="_blank"
                >
                  Ihr Weg zum Gold Label
                </a>
              </div>
            </div>

            <h3 className="additional-services__title">Zusätzliche Leistungen</h3>
            <div className="additional-services__container">
              <div className="additional-services__table">
                <div className="additional-services__table--row">
                  <div className="additional-services__table--cell">Rating Wiederholung* innerhalb 6 Monaten (Standard)</div>
                  <div className="additional-services__table--cell">€ 450</div>
                </div>
                <div className="additional-services__table--row">
                  <div className="additional-services__table--cell">Rating Wiederholung* innerhalb 6 Monaten (Silber/Gold)</div>
                  <div className="additional-services__table--cell">€ 750</div>
                </div>
                <div className="additional-services__table--row">
                  <div className="additional-services__table--cell">Späteres Upgrade von Standard auf Silber</div>
                  <div className="additional-services__table--cell">€ 750</div>
                </div>
                <div className="additional-services__table--row">
                  <div className="additional-services__table--cell">Späteres Upgrade von Silber auf Gold</div>
                  <div className="additional-services__table--cell">€ 200</div>
                </div>
                <div className="additional-services__table--row">
                  <div className="additional-services__table--cell">Zusätzliche Ausstellung eines Cyber Trust Europe Labels**</div>
                  <div className="additional-services__table--cell">€ 200</div>
                </div>
                <div className="additional-services__table--row">
                  <div className="additional-services__table--cell">Zusätzlich englische Version des Cyber Trust Austria Labels</div>
                  <div className="additional-services__table--cell">kostenfrei</div>
                </div>
              </div>
            </div>
            <div className="additional-services__footnotes">
              <p>* Im Falle der Nicht-Qualifikation im ersten Durchlauf</p>
              <p>**Voraussetzung: Erreichte Qualifikation für ein gleichwertiges Cyber Trust Austria Label</p>
            </div>

            <div className="asked-question__block--contact">
              <div>
                <h2>
                  <b>
                    Sie sind nicht sicher, welches Label das Richtige für Ihr
                    Unternehmen ist? <br></br>
                    Lassen Sie sich von unseren Experten kostenlos beraten.
                  </b>
                </h2>
              </div>
              <div>
                <div>
                  <a
                    style={{
                      // border: "4px solid",
                      padding: "10px 10px",
                      width: 275,
                      textDecoration: "none",
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="https://calendly.com/cyber-trust/30min"
                    target="_blank"
                  >
                    Zur kostenlosen Beratung
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default Preisuebersicht
